import throttle from 'lodash/throttle';

const scroll = () => {
    if ($(window).scrollTop() > 60) {
        $('html').addClass('scrolled');
    } else {
        $('html').removeClass('scrolled');
    }
};

$(() => {
    scroll();

    $(window).on('scroll', throttle(scroll, 100));
});
