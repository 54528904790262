$(() => {
    // Copy main nav to offcanvas
    const fullscreenMenuToggler = $('#fullscreenMenuToggler');
    const fullscreenMenu = $('#fullscreenMenu');

    function loadImages() {
        const images = document.querySelectorAll('.js-nav-item-image');

        images.forEach(image => {
            const src = image.getAttribute('data-src');
            image.setAttribute('src', src);
        });
    }

    if (fullscreenMenuToggler.length && fullscreenMenu.length) {

        fullscreenMenu.get(0).addEventListener('show.bs.offcanvas', event => {
            fullscreenMenuToggler.addClass('is-active');
            loadImages();
        })

        fullscreenMenu.get(0).addEventListener('hide.bs.offcanvas', event => {
            fullscreenMenuToggler.removeClass('is-active');
        })
    }
});
