//
// Prevent content jump
// In your code add '.js-prevent-content-jump' class to all elements with position: fixed;
//

$(() => {
    const offcanvasElements = document.querySelectorAll('.offcanvas');
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

    offcanvasElements.forEach(function (offcanvas) {

        offcanvas.addEventListener('show.bs.offcanvas', event => {
            $('.js-prevent-content-jump').css('padding-right', scrollbarWidth + 'px');
        })

        offcanvas.addEventListener('hidden.bs.offcanvas', function () {
            $('.js-prevent-content-jump').css('padding-right', '0px');
        });

    });
});
