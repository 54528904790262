function throttle(func, delay, options = {}) {
    let lastFunc;
    let lastRan;
    return function throttledFunction(...args) {
        const context = this;
        if (!lastRan) {
            if (options.leading !== false) {
                func.apply(context, args);
            }
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            if (options.trailing !== false && Date.now() - lastRan >= delay) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                lastFunc = setTimeout(() => {
                    if (options.leading === false && Date.now() - lastRan >= delay) {
                        lastRan = Date.now();
                        return;
                    }
                    func.apply(context, args);
                    lastRan = Date.now();
                    lastFunc = null;
                }, delay - (Date.now() - lastRan));
            }
        }
    };
}

export default throttle;
