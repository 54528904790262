$(() => {
    function handleLanguageClick() {
        $(this).closest('.switch-language').toggleClass('is-open');
    }

    const switchCurrentLanguage = $('.js-switch-current-language');

    if (switchCurrentLanguage.length) {
        switchCurrentLanguage.on('click', handleLanguageClick);
    }
});
