function isTouchDevice() {
    return (
        'ontouchstart' in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}

if (isTouchDevice()) {
    document.querySelector('html').classList.add('touch-device');
}

export default isTouchDevice;

