$(() => {
    const html = $('html');

    const headerLayout = html.attr('data-header-layout');
    const mainNavigation = html.attr('data-main-navigation');

    const multilingualArea = $('#multilingualArea').clone();
    const offcanvasMultilingual = $('#offcanvasMultilingual');

    const socialLinksArea = $('#socialLinksArea').clone();
    const offcanvasSocialLinks = $('#offcanvasSocialLinks');

    const fullscreenMenuMultilingual = $('#fullscreenMenuMultilingual');
    const fullscreenMenuSocialLinks = $('#fullscreenMenuSocialLinks');

    if (headerLayout === 'logo_left' && mainNavigation === 'default') {

        // Copy Multilingual block to offcanvas
        if (multilingualArea.children().length > 0) {
            offcanvasMultilingual.removeClass('d-none');
            offcanvasMultilingual.append(multilingualArea);
        }

        // Copy Social links block to offcanvas
        if (socialLinksArea.children().length > 0) {
            offcanvasSocialLinks.removeClass('d-none');
            offcanvasSocialLinks.append(socialLinksArea);
        }

    } else if (headerLayout === 'logo_left' && mainNavigation === 'fullscreen') {

        // Copy Multilingual block to fullscreen menu
        if (multilingualArea.children().length > 0) {
            fullscreenMenuMultilingual.removeClass('d-none');
            fullscreenMenuMultilingual.append(multilingualArea);
        }

        // Copy Social links block to offcanvas
        if (socialLinksArea.children().length > 0) {
            fullscreenMenuSocialLinks.removeClass('d-none');
            fullscreenMenuSocialLinks.append(socialLinksArea);
        }

    } else if (headerLayout === 'logo_centered' && mainNavigation === 'default') {

        // Copy Multilingual block to offcanvas
        if (multilingualArea.children().length > 0) {
            offcanvasMultilingual.removeClass('d-none');
            offcanvasMultilingual.append(multilingualArea);
        }

        // Copy Social links block to offcanvas
        if (socialLinksArea.children().length > 0) {
            offcanvasSocialLinks.removeClass('d-none');
            offcanvasSocialLinks.append(socialLinksArea);
        }

    } else if (headerLayout === 'logo_centered' && mainNavigation === 'fullscreen') {

        // Copy Multilingual block to offcanvas
        if (multilingualArea.children().length > 0) {
            fullscreenMenuMultilingual.removeClass('d-none');
            fullscreenMenuMultilingual.append(multilingualArea);
        }

        // Copy Social links block to fullscreen menu
        if (socialLinksArea.children().length > 0) {
            fullscreenMenuSocialLinks.removeClass('d-none');
            fullscreenMenuSocialLinks.append(socialLinksArea);
        }

    }
});
