import $ from 'jquery';
import throttle from '../throttle';

const htmlElement = $('html');
const buttonElement = $('#back-to-top');
const containerElement = $('.footer-bottom-container');

function updateCssVariables() {
    const viewportWidth = $(window).width();

    const containerStyles = getComputedStyle(containerElement[0]);
    const containerWidth = parseInt(containerStyles.width, 10);
    // const containerHeight = parseInt(containerStyles.height, 10);
    const containerPaddingRight = parseInt(containerStyles.paddingRight, 10);

    // const buttonHeight = buttonElement.height();

    const sidePush = (viewportWidth - containerWidth) / 2 + containerPaddingRight;
    // const bottomPush = containerHeight - buttonHeight;

    htmlElement.css('--back-to-top-side-push', `${sidePush}px`);
    // htmlElement.css('--back-to-top-bottom-push', `${bottomPush}px`);
    htmlElement.css('--back-to-top-bottom-push', `55px`);
}

function toggleClass() {
    if (window.scrollY + window.innerHeight > document.body.offsetHeight - 100) {
        buttonElement.addClass('moved-to-side');
    } else {
        buttonElement.removeClass('moved-to-side');
    }
}

function handleBackToTopClick() {
    $(window).scrollTop(0);
}

function handleWindowScroll() {
    toggleClass();
}

function handleWindowResize() {
    updateCssVariables();
}

const init = async () => {
    if (buttonElement.length && containerElement.length) {
        toggleClass();
        updateCssVariables();

        $(window).on('scroll', throttle(handleWindowScroll, 200));
        $(window).on('resize', throttle(handleWindowResize, 200));
        buttonElement.on('click', handleBackToTopClick);
    }
};

if ('requestIdleCallback' in window) {
    requestIdleCallback(init, { timeout: 2000 });
} else {
    init().then(() => {
    });
}
