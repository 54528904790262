$(() => {
    // Copy main nav to offcanvas
    const offcanvasMainNav = $('#offcanvasMainNav');
    const mainNavToggler = $('#mainNavToggler');

    if (offcanvasMainNav.length && mainNavToggler.length) {

        const mainNavList = $('#mainNavList').clone();

        // clone mainNavList to #offcanvasMenu
        $('#offcanvasMenu').append(mainNavList);

        offcanvasMainNav.get(0).addEventListener('show.bs.offcanvas', event => {
            mainNavToggler.addClass('is-active');
        })

        offcanvasMainNav.get(0).addEventListener('hide.bs.offcanvas', event => {
            mainNavToggler.removeClass('is-active');
        })
    }
});
