const animatedElements = document.querySelectorAll('[class*="ajs-"]');

function animate(element) {
    const observer = new window.IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                element.classList.add('ajs-animate');
            } else {
                if (element.classList.contains('ajs-repeat')) {
                    element.classList.remove('ajs-animate');
                }
            }
        },
        {
            root: null, // If you set overflow hidden on .ccm-page, you have to set ccm-page
            rootMargin: '20% 100% -10% 100%',
            threshold: 0,
        }
    );

    observer.observe(element);
}

if (animatedElements.length) {
    animatedElements.forEach((element) => {
        animate(element);
    });
}
