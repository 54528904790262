import $ from 'jquery';

const initLightbox = (item) => {
    $(item).magnificPopup({
        type: 'image',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        closeOnContentClick: true,
    });
};

const init = async () => {
    const lightboxes = document.querySelectorAll('[data-lightbox]');

    if (!lightboxes.length) return;

    lightboxes.forEach((lightbox) => {
        initLightbox(lightbox);
    });
};

if ('requestIdleCallback' in window) {
    requestIdleCallback(init, { timeout: 2000 });
} else {
    init().then(() => {});
}
